import {useCallback, useEffect, useState} from 'react';

import {Button, Card, CardBody, CardHeader, Col, Container, Modal, ModalBody, ModalFooter, Row} from 'reactstrap';
import {Formik} from 'formik';
import {escapeRegExp, uniq} from 'lodash';

import {
  CustomTable,
  Duration,
  DurationType,
  FormikCheckboxGroup,
  FormikSearchInput,
  FormikSelect,
  ProgressIndicator,
  SsoNotification,
  TabNav,
  useAlerts,
  UserNotification,
  useTabNav,
  withTabNav
} from '@reasoncorp/kyber-js';

import {ssoApi} from '../api';
import {formatDateTime} from '../util';
import * as messages from '../messages';

type SsoNotificationMap = {
  [id: string]: SsoNotification
}

const filterBySearchText = (userNotification: UserNotification, searchText: string) => {
  const searchTermRegexp = RegExp(escapeRegExp(searchText), 'i');
  return {
    read: userNotification.read.filter(notification => searchTermRegexp.test(notification.subject)),
    archived: userNotification.archived.filter(notification => searchTermRegexp.test(notification.subject)),
    unread: userNotification.unread.filter(notification => searchTermRegexp.test(notification.subject))
  } as UserNotification;
};

const filterByApplication = (userNotification: UserNotification, application: string) => {
  return {
    read: userNotification.read.filter(notification => notification.application === application),
    archived: userNotification.archived.filter(notification => notification.application === application),
    unread: userNotification.unread.filter(notification => notification.application === application)
  } as UserNotification;
};

const getAllNotifications = (userNotification: UserNotification) => {
  return [
    ...userNotification.read,
    ...userNotification.unread,
    ...userNotification.archived
  ];
};

const Notifications = () => {
  const [filteredNotifications, setFilteredNotifications] = useState<SsoNotification[]>([]);
  const [selectedNotification, setSelectedNotification] = useState<SsoNotification | undefined>(undefined);
  const [loadingState, setLoadingState] = useState({
    loading: true,
    loadError: false
  });
  const [messageModalIsOpen, setMessageModalIsOpen] = useState(false);
  const {selectedTab, setTabDisplayValue} = useTabNav();
  const [applications, setApplications] = useState<string[]>([]);
  const [selectedApplication, setSelectedApplication] = useState<string>('');
  const [searchText, setSearchText] = useState<string>('');
  const [notificationMap, setNotificationMap] = useState<SsoNotificationMap>({});
  const {showErrorAlert} = useAlerts();

  const findNotifications = useCallback(
    async () => {
      try {
        const userNotification = await ssoApi.findNotifications();

        let allNotifications = getAllNotifications(userNotification);
        setApplications(uniq(allNotifications.map(notification => notification.application)).sort());

        const notificationMap = {} as SsoNotificationMap;
        allNotifications.forEach(notification => {
          notificationMap[notification.id] = notification;
        });
        setNotificationMap(notificationMap);

        // Filter by tab, application, and search text
        let filteredNotifications: SsoNotification[];
        let filteredUserNotification: UserNotification = userNotification;

        if (selectedApplication !== '') {
          filteredUserNotification = filterByApplication(userNotification, selectedApplication);
        }

        if (searchText !== '') {
          filteredUserNotification = filterBySearchText(filteredUserNotification, searchText);
        }

        allNotifications = getAllNotifications(filteredUserNotification);

        setTabDisplayValue('unread', `Unread (${filteredUserNotification.unread.length})`);
        setTabDisplayValue('read', `Read (${filteredUserNotification.read.length})`);
        setTabDisplayValue('archived', `Archived (${filteredUserNotification.archived.length})`);
        setTabDisplayValue('all', `All (${allNotifications.length})`);

        if (selectedTab === 'archived') {
          filteredNotifications = filteredUserNotification.archived;
        } else if (selectedTab === 'read') {
          filteredNotifications = filteredUserNotification.read;
        } else if (selectedTab === 'unread') {
          filteredNotifications = filteredUserNotification.unread;
        } else {
          filteredNotifications = allNotifications;
        }

        setFilteredNotifications(filteredNotifications);
        setLoadingState({loading: false, loadError: false});
      } catch (error) {
        showErrorAlert(messages.UNABLE_TO_RETRIEVE_NOTIFICATIONS, true);
        setLoadingState({loading: false, loadError: false});
      }
    },
    [setTabDisplayValue, showErrorAlert, selectedTab, selectedApplication, searchText]
  );

  useEffect(() => {
      findNotifications().then();
      // poll every 10 minutes
      const interval = setInterval(findNotifications, Duration.of(10, DurationType.MINUTES));

      // clear the interval out when input change
      return () => {
        clearInterval(interval);
      };
    },
    [findNotifications]
  );

  const readNotification = useCallback(
    async (notificationToAcknowledge: SsoNotification) => {
      try {
        // Remove from the list immediately and then do an async request.
        await ssoApi.readNotification(notificationToAcknowledge.id);
        await findNotifications();
      } catch (error) {
        // Do nothing if the request fails because the interval will
        // refresh the list again. The user will have to delete it again.
        // The hope is that this will rarely happen, and if so will be raised as a bug.
      }
    },
    [findNotifications]
  );

  const archiveNotification = useCallback(
    async (notificationToAcknowledge: SsoNotification) => {
      try {
        // Remove from the list immediately and then do an async request.
        await ssoApi.archiveNotification(notificationToAcknowledge.id);
        await findNotifications();
      } catch (error) {
        // Do nothing if the request fails because the interval will
        // refresh the list again. The user will have to delete it again.
        // The hope is that this will rarely happen, and if so will be raised as a bug.
      }
    },
    [findNotifications]
  );

  const handleViewNotification = (notification: SsoNotification) => {
    setSelectedNotification(notification);
    setMessageModalIsOpen(true);
  };

  const handleClose = async (notification: SsoNotification) => {
    try {
      await readNotification(notification);
      setMessageModalIsOpen(false);
    } catch (e) {
      // ignore
    }
  };

  const tableProps = {
    headers: [
      {title: 'Application', sortKey: 'application', className: 'w-5 text-center'},
      {title: 'Subject', sortKey: 'subject'},
      {title: 'Received', sortKey: 'createdAt', className: 'w-15'},
      {title: 'Read', className: 'w-10 text-center'},
      {title: 'Archived', className: 'w-10 text-center'}
    ],
    items: filteredNotifications,
    paginatorConfig: {
      perPage: 20,
      recordName: 'notifications',
      allowShowAll: true
    },
    noResultsMessage: `No ${selectedTab} notifications.`,
    renderRow: (notification: SsoNotification) => {
      return <tr key={`notification-${notification.id}`}>
        <td className="font-weight-bold text-primary text-center align-middle text-nowrap">
          {notification.application}
        </td>
        <td className="align-middle">
          <Button color="link"
                  className="p-0"
                  onClick={() => handleViewNotification(notification)}>
            {notification.subject}
          </Button>
        </td>
        <td className="align-middle">{formatDateTime(notification.createdAt)}</td>
        <td className="text-center align-middle">
          <FormikCheckboxGroup type="switch"
                               checkboxes={[
                                 {
                                   ariaLabel: `Toggle Read for notification: '${notification.subject}'`,
                                   name: `notifications['${notification.id}'].read`,
                                   onChange: () => readNotification(notification)
                                 }
                               ]}/>
        </td>
        <td className="text-center align-middle">
          <FormikCheckboxGroup type="switch"
                               checkboxes={[
                                 {
                                   ariaLabel: `Toggle Archived for notification: '${notification.subject}'`,
                                   name: `notifications['${notification.id}'].archived`,
                                   onChange: () => archiveNotification(notification)
                                 }
                               ]}/>
        </td>
      </tr>;
    }
  };

  return (
    <Container fluid className="Notifications">
      {loadingState.loading && <ProgressIndicator/>}
      {!loadingState.loading &&
        <Formik initialValues={{
          selectedApplication,
          notifications: notificationMap
        }}
                onSubmit={async () => null}
                enableReinitialize={true}>
          {(_) => <>
            <Row>
              <Col>
                <Card>
                  <CardHeader className="bg-secondary text-white">
                    <Row className="d-flex justify-content-between">
                      <Col>
                        {selectedApplication !== '' ? `${selectedApplication} Notifications` : 'Notifications'}
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardHeader className="bg-white">
                    <Row className="d-flex justify-content-between">
                      <Col className="col-4 pt-2">
                        <TabNav/>
                      </Col>
                      <Col className="col-8 justify-content-end d-flex">
                        <FormikSelect name="selectedApplication"
                                      ariaLabel="Filter by Application"
                                      onChange={(e) => setSelectedApplication(e.target.value)}>
                          <option value="">All Applications&nbsp;</option>
                          {applications.map(application =>
                            <option key={application} value={application}>{application}</option>)}
                        </FormikSelect>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <FormikSearchInput onSubmit={(searchText) => setSearchText(searchText)}
                                       disableFloatingLabel={true}
                                       disabled={loadingState.loading || loadingState.loadError}/>
                    <div className="mt-4">
                      <CustomTable {...tableProps} />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {selectedNotification && <Modal size="lg"
                                            backdrop="static"
                                            toggle={() => setMessageModalIsOpen(!messageModalIsOpen)}
                                            isOpen={messageModalIsOpen}>
              <Card>
                <CardHeader>
                  <Row>
                    <Col className="col-6">
                      <h5 className="mb-0">
                        {selectedNotification.subject}
                      </h5>
                    </Col>
                    <Col className="col-6 d-flex justify-content-end">
                      {formatDateTime(selectedNotification.createdAt)}
                    </Col>
                  </Row>
                </CardHeader>
                <ModalBody style={{minHeight: '450px'}}>
                  {selectedNotification.text}
                </ModalBody>
                <ModalFooter>
                  <Row className="d-flex justify-content-end mr-0 pr-0">
                    <Col className="mr-0 pr-0">
                      <Button className="mr-2"
                              color="primary"
                              onClick={() => archiveNotification(selectedNotification)}>Archive</Button>
                      <Button onClick={() => handleClose(selectedNotification)}>Close</Button>
                    </Col>
                  </Row>
                </ModalFooter>
              </Card>
            </Modal>}
          </>}
        </Formik>
      }
    </Container>
  );
};

export default withTabNav(Notifications, {
  tabs: [
    {value: 'all', displayValue: 'All'},
    {value: 'unread', displayValue: 'Unread'},
    {value: 'read', displayValue: 'Read'},
    {value: 'archived', displayValue: 'Archived'}
  ],
  initialTab: 'unread'
});